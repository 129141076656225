import { formatDate } from "@/helpers/dateProcessor";

export const tableColumnsListFaq = {
  question: {
    formatDataKey: "Question",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 0
  },
  "createdByData.fullName": {
    formatDataKey: "Created By",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 1,
    colWidth: 200
  },
  createdOn: {
    formatDataKey: "Created At",
    formatDataValue: (arg: string) => formatDate(arg),
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 2,
    colWidth: 300
  }
};
